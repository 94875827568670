<!-- 作业首页 -->
<style lang="scss" scoped>
@include pageFoot;
::v-deep .el-tabs__header{
    line-height: 60px;
    background-color: #FFFFFF;
    padding: 0 20px 10px;
    border-radius: 5px;
    margin-top: 20px;   
}
.list{
    display: inline-block;
    height: calc(100vh - 190px);
    padding-bottom: 20px;
    overflow: auto;
    width: 100%;
    position: relative;
    
    .item{
        padding-top: 20px;
        display: inline-block;
        width: 100%;
        &:last-child{
            margin-bottom: 20px;
        }
        .day{
            height: 30px;
            font-size: 18px;
            font-weight: bold;
            color: #222222;
            line-height: 30px;
            .shu{
                width: 1px;
                height: 20px;
                background-color: #AAAAAA;
                margin: 0 10px;
            }
            .date{
                font-size: 16px;
                color: #888888;
                line-height: 28px;
            }
        }
        .li{
            margin-top: 10px;
            background-color: #FFFFFF;
            padding: 20px 20px 14px;
            border-radius: 14px;
            box-shadow: 0px 14px 27px -17px #B7D0DE;
            &:hover{
                cursor: pointer;
                box-shadow: 0px 14px 27px -17px #9bb0bd;
            }
            .name{
                height: 20px;
                font-size: 16px;
                font-weight: 400;
                color: #444444;
                line-height: 20px;
                margin-bottom: 10px;
            }
            .endDate{
                height: 28px;
                font-size: 14px;
                font-weight: 400;
                color: #01A1FD;
                line-height: 28px;
                background: rgba(1, 161, 253, 0.1);
                border-radius: 100px;
                padding: 0 10px;
            }
            .examine{
                width: 70px;
                height: 28px;
                border-radius: 100px;
                line-height: 28px;
                font-size: 14px;
                margin-left: 10px;
                text-align: center;
                &.type1{
                    background: rgba(255,124,18,0.1);
                    color: $comOrange;
                }
                &.type2{
                    background: rgba(170,170,170,0.1);
                    color: $comAAA;
                }
                &.type3{
                    background: #FDEBEB;
                    color: $comGray;
                }
            }
            .btn{
                width: 100px;
                height: 36px;
                border-radius: 6px;
                color: #FFFFFF;
                font-size: 16px;
                line-height: 36px;
                text-align: center;
                &.type1{
                    background: $comBlue;
                }
                &.type2{
                    background: $comGray;
                    color: #888888;
                }
            }
            .title{
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #444444;
                line-height: 22px;
            }
            .remark{
                width: 100%;
                background-color: #FFFFFF;
                border-radius: 14px;
                position: relative;
                display: inline-block;
                .iconimg{
                    position: absolute;
                    left: 5px;
                    top: 13px;
                    width: 26px;
                }
                .teacherRemark{
                    padding: 0 20px 0 40px;
                    height: 30px;
                    background: rgba(255, 124, 18, 0.102);
                    border-radius: 30px 30px 30px 0px;
                    line-height: 30px;
                    text-align: center;
                    font-size: 14px;
                    color: #FF7C12;
                    font-weight: bold;
                    margin-top: 10px;
                    float: left;
                }
                .value{
                    clear: both;
                    font-size: 14px;
                    line-height: 22px;
                    color: #FF7C12;
                    padding-top: 8px;
                }
            }
        }
    }
}
</style>

<template>
    <div class="index">
        <div class="list" v-loading="loading"> 
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="day flex_align">
                    {{item.week}} <div class="shu"></div><div class="date">{{item.date}}</div>
                </div>
                <div v-for="(item2,index2) in item.data" :key="index2">
                    <div class="li" @click="goDetails(item2)">
                        <div class="flex_bet_align">
                            <div class="name">{{item2.sysub_name}}</div>
                            <div class="flex_align">
                                <!-- <div class="endDate">截止时间：{{item2.sthom_wor_deadlinetime}}前</div> -->
                                <div class="examine type1" v-if="item2.sthom_wor_status==30">已批阅</div>
                                <div class="examine type2" v-if="item2.sthom_wor_status==10">待批阅</div>
                            </div>
                            <!-- <div class="btn type1" v-if="item2.sthom_wor_status==20&&showAchieve(item2.sthom_wor_deadlinetime)">去完成</div>
                            <div class="btn type2" v-if="item2.sthom_wor_status!=20">已完成</div> -->
                        </div>
                        <div class="title clamp2">{{item2.sthom_wor_remark}}</div>
                        <!-- 老师评语 -->
                        <div class="remark" v-if="item2.sthom_wor_status==30">
                            <img class="iconimg" src="@assets/images/studentpingyuicon.png" alt="">
                            <div class="teacherRemark flex_align">
                            {{item2.sthom_wor_show==10?"评分":"评级"}}
                            <div class="w-20"></div>
                            {{item2.sthom_wor_show==10?item2.sthom_wor_score:item2.sthom_wor_grade}}
                            </div>
                            <div class="value">{{item2.sthom_wor_comment?item2.sthom_wor_comment:""}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-data--empty" v-if="$isEmpty(list)">
                <img src="@assets/images/no-data3.png" alt="">
                <p>暂无作业数据哦~</p>
            </div>
        </div>
        <!-- 分页控件 -->
        <div class="custom-foot">
            <customPagination :current='tablePage.pageIndex' :total="tablePage.total" :size="tablePage.pageSize" @pageChange="flippingPage" />
        </div>
    </div>
</template>

<script>
import { homework_list } from "@api/home"
import customPagination from "@comp/customPagination"
export default {
    name: 'jobIndex',
    components: { customPagination },
    data() {
        return {
            list: [], // 列表数据
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                pageSize: 10,
                total: 0
            },
            loading:false,
            typeIndex:"0",
        }
    },
    created() {
        this.getData();
    },
    methods: {
       
        /** 获取公开课列表数据 */
        async getData() {
            this.loading=true
            let params={
                pagesize:this.tablePage.pageSize,
                pageindex:this.tablePage.pageIndex,
                sthom_wor_type:20
            }
            let { data: res } = await homework_list(params);
            this.loading=false
            this.tablePage.total = res.allcount;
            this.list = res.data;
            this.$forceUpdate();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        //跳转详情
        goDetails(item){
            if(item.sthom_wor_status==20){
                this.$router.push({
                    name:"JOB_DETAILS_NOT",
                    params: {
                    id:item.sthom_wor_id,
                    type:2
                    }
                })
            }else{
                this.$router.push({
                    name:"JOB_DETAILS",
                    params: {
                        id:item.sthom_wor_id,
                        school:0,
                        accomplish:0
                    }
                })
            }
        },
        //判断去完成字段是否显示
        showAchieve(date){
            try{
                return new Date(date.replace(/-/g, '/')) > new Date()
            } catch (e) {
                return false
            }
        },
        //切换
        handleClick() {
            this.getData()
        }
    },
}
</script>